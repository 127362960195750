@import "~@camtv/front-base-css/main/_variables.scss";
body {
    padding:52px 0 0 0;
    margin:0;
}
.header {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    padding: 6px 15px;
    background-color: #fff;
    background-image: linear-gradient(150deg,#f6f9fc 0%,#fff0e2 100%);
    z-index: 60;
    min-height:52px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    

    .logo {
        max-width: 120px;
        a {
            display: block;
            svg {
                margin: 0 !important
            }
        }
    }
    
    .logout-link{
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
            color:var(--color-text-dark);
            font-size:14px;
            //text-decoration:underline;
        }
        svg {
            color:var(--color-text-medium);
            width:24px;
            height:24px;
            margin-right: 4px;
        }
    }

    .language-switcher {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        margin-left: 1rem;
        max-width:160px;
        position:relative;

        .selected-content {
            padding:2px 10px;
            height:40px;
            background: transparent;
            border: 0;
            svg {
                //display:none;
            }
        }
        .list-container {
            padding: 4px;
            min-height: 215px;
            display: none;
            position: absolute;
            border: 1px solid #00000026;
            background-color: #FFF;
            width: 110px;
            box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
            z-index: 10;
            overflow-y: auto;
            border-radius: 5px;
            left: 40px;
            top: 35px;
            .item-row {
                margin:10px 5px;
            }
        }

        > .item-row {
            width:150px;
            flex-direction: row-reverse;
        }
        
        .item-row {
            color: #000;
            text-decoration:none;
            display: flex;
            align-items: center;
            margin:0;
            a,
            a:hover,
            a:focus,
            a:active,
            a:visited {
                color:var(--color-text-black);
                text-decoration: none;
                display: flex;
                align-items: center;
            }
            img {
                max-width: 30px;
                width:30px;
                height:30px;
                vertical-align: middle;
                margin: 0 !important
            }
            span {
                vertical-align: middle;
                margin-left: 10px
            }
        }
        
    }
}
.path-header {
    background-image: url('https://s3staticassets.cam.tv/release/2021-12-16/sfondo-2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 160px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-shadow: 0 6px 0 0 rgba(0,0,0,0.1);
    @include media-sm {
        padding-top:20px;
        min-height: 164px;
    }
    
}








